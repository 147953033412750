const { Validation } = require('./validation')
import IMask from 'imask'

const maskOptions = {
  mask: '+{38} (000) 000-00-00'
};

const phoneInput = document.querySelector('input[name=phone]');
if (phoneInput) IMask(phoneInput, maskOptions);

// Validate question form

const form = document.querySelector('form[name=questionForm]');

if (form) {
  const validationOptions = [
    {
      field: form.querySelector('input[name=fio]'),
      rules: {
        required: true,
        cyrillic: true
      },
      onchange: true
    },
    {
      field: form.querySelector('input[name=email]'),
      rules: {
        required: true,
        email: true
      },
      onchange: true
    },
    {
      field: form.querySelector('input[name=phone]'),
      rules: {
        required: true,
        phone: 12
      },
      onchange: true
    },
    {
      field: form.querySelector('textarea[name=email_body]'),
      rules: {
        required: true
      },
      onchange: true
    }
  ];

  const validation = new Validation({
    singleTextError: true
  });

  validationOptions.forEach(el => {
    if (el.onchange === true) {
      el.field.addEventListener('change', () => validation.startValidate([el]));
    }
  })


  form.onsubmit = (e) => {
    let state = validation.startValidate(validationOptions);
    
    if (!state) {
      e.preventDefault();
    }
  }
}
