class Validation {
  constructor({ singleTextError }) {
    this.invalidities = {};
    this.singleTextError = singleTextError || false;
  }

  startValidate(rules) {
    rules.forEach((item) => {
      if (item.rules !== undefined) {
        let allMsg = [];

        for (let rule in item.rules) {
          switch (rule) {
            case 'required':
              allMsg.push(this.checkRequired(item.field))
              break;

            case 'maxLength':
              allMsg.push(this.checkMaxLength(item.field.value, item.rules[rule]))
              break;

            case 'length':
              allMsg.push(this.checkLength(item.field.value, item.rules[rule]))
              break;

            case 'type':
              allMsg.push(this.checkType(item.field.value, item.rules[rule]))
              break;

            case 'cyrillic':
              allMsg.push(this.checkCyrillic(item.field.value))
              break;

            case 'email':
              allMsg.push(this.checkEmail(item.field.value))
              break;

            case 'phone':
              allMsg.push(this.checkPhone(item.field.value, item.rules[rule]))
              break;

          }
        }

        const result = allMsg.filter((msg) => msg !== "");

        this.invalidities[item.field.name] = {
          field: item.field,
          errors: this.singleTextError ? [result[0]] : result,
          invalid: result.length === 0 ? false : true
        };
      }
    });
    this.addTextErrors();
    this.addBorderErrors();
    return this.setState();
  }

  checkRequired(field) {
    let msg = '';

    if (field.value.length === 0 || field.selectedIndex === 0 || field.value.replace(/ /g, '').length === 0) {
      msg = `Це обов'язкове поле`;
    }

    return msg;
  }

  checkMaxLength(value, ruleProperty) {
    let msg = '';

    if (value.length > ruleProperty && value.length !== 0) {
      msg = 'Кількість символів не повинно перевищувати ' + ruleProperty;
    }

    return msg;
  }

  checkLength(value, ruleProperty) {
    let msg = '';

    if (value.length !== ruleProperty && value.length !== 0) {
      msg = 'Допустима кількість символів ' + ruleProperty;
    }

    return msg;
  }

  checkType(value, ruleProperty) {
    let msg = '';
    switch (ruleProperty) {
      case 'string': {
        if ((new RegExp(/\d/)).test(value)) {
          msg = 'Поле має містити тільки букви';
        }
      } break;

      case 'number': {
        if ((new RegExp(/[^0-9]/gi)).test(value)) {
          msg = 'Поле має містити тільки цифри';
        }
      } break;
    }

    return msg;
  }

  checkCyrillic(value) {
    let msg = '';

    if ((new RegExp(/[^А-ЯIЁЇІЄҐ ]/gi)).test(value)) {
      msg = 'Дозволені лише символи кирилиці';
    }

    return msg;
  }

  checkEmail(value) {
    let msg = '';

    if (!(new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i)).test(value)) {
      msg = 'Невірний формат e-mail';
    }

    return msg;
  }

  checkPhone(value, rule) {
    let msg = '';

    if (value.length !== 0) {
      if (value.match(/\d/g).join('').length !== rule) {
        msg = 'Невірний формат телефону';
      }
    }

    return msg;
  }

  createErrorElement(msg) {
    let errorBlock = document.createElement('span');
    errorBlock.innerText = msg;
    errorBlock.classList.add('error-v')

    return errorBlock;
  }

  addTextErrors() {
    for (let item in this.invalidities) {
      const element = this.invalidities[item];
      const parentBlock = element.field.parentNode;

      if (parentBlock.querySelector('.error-v')) {
        parentBlock.querySelector('.error-v').innerText = element.errors.join('. \n');
      } else {
        parentBlock.append(this.createErrorElement(element.errors.join('. \n')));
      }
    }
  }

  addBorderErrors() {
    for (let item in this.invalidities) {
      const element = this.invalidities[item];

      if (element.invalid) {
        element.field.style.border = '1px solid rgb(185, 74, 72)';
      } else {
        element.field.style.border = '1px solid #212529';
      }
    }
  }

  setState() {
    const arrayOfState = [];

    for (let item in this.invalidities) {
      arrayOfState.push(this.invalidities[item].invalid)
    }

    return arrayOfState.every((el) => el === false)
  }
}

module.exports = { Validation }